import * as actionTypes from './actionTypes';

export const setInvoiceInfo = (token: string, practiceName: string, practiceLogo: string, practicePhoneNumber: string, invoice: any) => {
    return (dispatch: any) => {
        dispatch({        
            type: actionTypes.SET_INVOICE_INFO, 
            payload: { 
                token, 
                practiceName,
                practiceLogo, 
                practicePhoneNumber, 
                invoice
            }
        });
    }
}

export const deleteInvoiceInfo = () => {
    return (dispatch : any) => {
        dispatch({type: actionTypes.DELETE_INVOICE_INFO});
    }
}

export const setPaymentProcessed = (paymentAmount: number, allowSendEmail: boolean) => {
    return (dispatch : any) => {
        dispatch({
            type: actionTypes.SET_PAYMENT_PROCESSED,
            payload: {
                allowSendEmail,
                paymentAmount
            }
        });
    }
}