import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import { useIdleTimer } from 'react-idle-timer'

import Payments from './pages/Payments';
import PaymentDetails from './pages/PaymentDetails';
import PaymentProcessed from './pages/PaymentProcessed';
import NotFound from './pages/NotFound';
import Unauthorized from './pages/Unauthorized ';
import Idle from './pages/Idle';

import "./App.css?version=7.0.204.8";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {
    const handleOnIdle = () => {
        window.location.href="/idle";
    }

    useIdleTimer({
        timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES) * 60000,
        onIdle: handleOnIdle,
        debounce: 500
    });  

    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/payments/request-payment/:token" component={Payments} />        
            <Route path="/payments/payment-details" component={PaymentDetails} />
            <Route path="/payments/payment-processed" component={PaymentProcessed} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/not-authorized" component={Unauthorized } />
            <Route path="/idle" component={Idle} />
            <Redirect to="/not-found"/>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
};

export default App;
