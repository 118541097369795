import * as React from 'react'
import { IonLoading } from '@ionic/react';

interface LoadingProps {
    show : boolean,
    onDidDismiss : () => void,
    message : string,
    duration? : number,
  };
  
  const Loading : React.FC<LoadingProps> = (props) => {
    return (
        <IonLoading
            mode="ios"
            isOpen={props.show}
            onDidDismiss={() => props.onDidDismiss()}
            message={props.message}
            duration={props.duration}
        />           
    );
  }

  export default Loading;