import React from "react";
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { connect } from "react-redux";

import PoweredBy from "../components/PoweredBy";
import PracticeLogo from "../components/PracticeLogo";

import clockCircle from '../assets/clock-circle.png';

interface IdleProps {
    practiceName: string,
    practiceLogo: string, 
}

const Idle : React.FC<IdleProps> = (props) => {
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar mode="ios">
                <IonTitle>Idle timer expired</IonTitle>
            </IonToolbar>
            </IonHeader>
            <IonContent className="content">
                <IonGrid style={{ paddingTop:"20px" }}>        
                    <IonRow>
                        <IonCol
                            offsetXl="2"
                            sizeXl="8"
                        >
                            <IonCard>
                                <IonCardContent style={{ padding:"20px 15px" }}>
                                    <IonGrid className="ion-no-padding">
                                        { props.practiceLogo &&
                                        <IonRow>
                                            <IonCol>
                                                <PracticeLogo src={props.practiceLogo} textAlt={props.practiceName} customStyle={{marginBottom:"25px"}} />
                                            </IonCol>
                                        </IonRow>                                                        
                                        }
                                        <IonRow>
                                            <IonCol>
                                                <IonImg src={clockCircle} className="checkCircle" style={{marginBottom:"25px"}}/>
                                            </IonCol>
                                        </IonRow>                                          
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <p className="subtitle">For your security, the payment session has expired due to inactivity.</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <p className="subtitle">Please click the payment link you received to try again.</p>
                                            </IonCol>
                                        </IonRow>                                        
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <PoweredBy />
            </IonContent>
        </IonPage>
    );
}

const mapStateToProps = (state : any) => {
    return {
        practiceName: state.payments.practiceName,
        practiceLogo: state.payments.practiceLogo,        
    }
}

export default connect(mapStateToProps) (Idle);