import React from 'react';
import { IonCol, IonImg, IonLabel, IonRow } from '@ionic/react';

import patientNowLogo from '../assets/patientnow.svg';
import packageJson from '../../package.json';

const PoweredBy : React.FC = () => {
    return(
        <IonRow>
            <IonCol>
            <div className="ion-text-center">       
                <IonLabel>
                <h3 style={{color:"#a0a5b9", fontWeight:600, margin:'20px 0px 10px'}}>POWERED BY</h3>
                </IonLabel>              
            </div>
            <IonImg src={patientNowLogo} className="patientNowLogo"  />
            <div className="divVersion">
                <small>version {packageJson.version}</small>          
            </div>
            
            </IonCol>
        </IonRow>        
    );
}

export default PoweredBy;