import React from 'react';

interface H3PaddedProps{
    text: string,
    customStyle?: React.CSSProperties | undefined,
    cssClass?: string | undefined,
    bold?: boolean | undefined
}

const H3Padded: React.FC<H3PaddedProps> = (props) => {

    const boldH3 = props.bold && "boldH3";

    return (
        <h3 className={`padding-3 ${ props.cssClass} ${ boldH3 }`} style={props.customStyle}>{props.text}</h3>
    );
}

export default H3Padded;