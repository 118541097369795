import React from "react";

interface FontAwesomeIconProps{
    className: string | undefined;
}

const FontAwesomeIcon : React.FC<FontAwesomeIconProps> = (props) => {
    return (<i className={props.className} />);
}

export default FontAwesomeIcon;