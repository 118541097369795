import React, { useState } from "react";
import { Loading } from "pn-components";
import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";

import PoweredBy from "../components/PoweredBy";

const NotFound: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);

    const onLoadingDismiss = () => {
        //do nothing
      }
    
      const loadingLibraryClick = () => {
        setShowLoading(true);
      }
      
    console.log("REACT_APP_API_BASE_URL", process.env.REACT_APP_API_BASE_URL);
    console.log("REACT_APP_API_BASE_URL", process.env.REACT_APP_API_BASE_URL);
    return (
        <IonPage>
            <IonContent className="content">
                <IonGrid style={{ paddingTop:"20px" }}>        
                    <IonRow>
                        <IonCol
                            offsetXl="2"
                            sizeXl="8"
                        >
                            <IonCard>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <div className="ion-text-center not-found">404</div> 
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <p className="subtitle">The requested URL was not found on this server.</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol
                                                offsetMd="4"
                                                sizeMd="4"                                            
                                                offsetSm="3"
                                                sizeSm="6"
                                            >
                                                <IonButton onClick={loadingLibraryClick}>Loading from library</IonButton>
                                            </IonCol>
                                        </IonRow>                                        
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <PoweredBy />
                <Loading show={showLoading} onDidDismiss={onLoadingDismiss} message="Please wait..." />
            </IonContent>
        </IonPage>
    );
}

export default NotFound;