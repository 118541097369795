//package: https://github.com/xnimorz/vanilla-masked-input guide: https://www.npmjs.com/package/react-maskinput
import React, { useCallback } from 'react';
import { IonInput } from '@ionic/react';
import Inputmask from "inputmask";

import NumberFormatter from '../Functions/Number';

interface IonInputMaskProps {
    value : React.Key, 
    maskType: "currency" | "creditcardnumber" | "creditardexpiration",
    onValueChanged: (value: string) => void    
    name?: string, 
    maxlength?: number, 
    onIonFocus?: (event: CustomEvent) => void
}

const IonInputMask : React.FC<IonInputMaskProps> = (props) => {
    const inputCallback = useCallback(async (input) => {
        if (!input) {
          return;
        }

        const nativeInput = await input.getInputElement();
        if(props.name) nativeInput.name = props.name;
        if(props.maxlength) nativeInput.maxLength = props.maxlength;
        if(props.onIonFocus) {
            nativeInput.addEventListener('focus', (event: any) => {                
                var customEvent: CustomEvent = new CustomEvent("onIonFocus", { detail: event });
                props.onIonFocus!(customEvent);
            });
        }

        switch(props.maskType){
            case "currency":
                Inputmask({ alias:"currency", showMaskOnHover: false }).mask(nativeInput);

                nativeInput.addEventListener('input', (event: any) => {
                    const inputValue = event.target.value === "" ? event.target.value : NumberFormatter.StringToNumber(event.target.value);
                    props.onValueChanged(inputValue);
                });

                nativeInput.addEventListener('keyup', (event: any) => {
                    if(event.key === "." || event.key === "Backspace" || event.key === "Delete"){
                        const inputValue = event.target.value === "" ? event.target.value : NumberFormatter.StringToNumber(event.target.value);
                        props.onValueChanged(inputValue);
                    }
                });

                nativeInput.inputMode = "decimal";
                break;
            case "creditcardnumber":
                Inputmask({ mask:"9999 9999 9999 9999", showMaskOnFocus: false, showMaskOnHover: false, placeholder:'' }).mask(nativeInput);
                nativeInput.addEventListener('input', (event: any) => {
                    props.onValueChanged(event.target.value);
                });
                break;
            case "creditardexpiration":
                Inputmask({ mask:"99/99", showMaskOnFocus: false, showMaskOnHover: false, placeholder:'' }).mask(nativeInput);
                nativeInput.addEventListener('input', (event: any) => {
                    props.onValueChanged(event.target.value);
                });
                break;
        }

        nativeInput.addEventListener('paste', (event: any) => {
            const value = props.maskType === "currency" ? NumberFormatter.StringToNumber(event.target.value) : event.target.value;
            props.onValueChanged(value);
        });

        nativeInput.addEventListener('cut', () => {
            props.onValueChanged("");
        });

      }, []);
    return (
        <IonInput {...props} ref={inputCallback} />
    );
}

export default IonInputMask;