import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonText } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React from "react";

interface ErrorBoxProps {
    message: string,
}

const ErrorBox : React.FC<ErrorBoxProps> = (props) => {
    return (
      <IonCard className="errorBox">
        <IonCardContent>
          <IonItem className="transparentItem" lines="none">
            <IonButton mode="md" fill="clear">
              <IonIcon slot="icon-only" icon={closeCircle} color="danger" />
            </IonButton>
            <div className="word-wrap">                          
                <IonText color="danger">{props.message}</IonText>
            </div> 
          </IonItem>
        </IonCardContent>
      </IonCard>
    );
}

export default ErrorBox;

